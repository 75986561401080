
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "animate.css/animate.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .menu-item{
    @apply exile-flex exile-items-center exile-p-2 exile-text-base exile-font-normal exile-text-gray-900 exile-rounded-lg dark:exile-text-white hover:exile-bg-gray-100 dark:hover:exile-bg-gray-700
  }
  .menu-item-icon{
    //@apply exile-w-6 exile-h-6 exile-text-gray-500 exile-transition exile-duration-75 dark:exile-text-gray-400 hover:exile-text-gray-900 exile-flex exile-items-center ;

  }
}

@media (prefers-color-scheme: dark) {
  body {
    --color-accent-primary: 70 43 135;
    --color-accent-secondary: 255 152 51;
    --color-shade-primary: 29 27 24;
    --color-shade-secondary: 26 23 18;
    --color-shade-card: 18 17 14;
    --color-stroke: 57 57 57;
    --color-text-dark: 240 240 240;
    --color-text-light: 142 149 168;
    --color-text-contrast: 255 255 255;
    --color-error: 235 87 87;
  }
}
@font-face {
  font-family: "satisfyregular";
  src: url("./assets/fonts/satisfy-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/satisfy-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

app-menu {
  .owl-carousel .owl-item img {
    border: 1px solid #fff;
    border-radius: 12px;
  }

  .owl-theme .owl-nav [class*="owl-"] {
    width: 5rem;
    background-color: #fff;
    color: #000;
  }
}
// @import url{"ass"}
.section-shaped .shape-style-1.shape-default,
section.bg-gradient-default {
  background: linear-gradient(
    150deg,
    #151412 15%,
    #151412 70%,
    #151412 94%
  ) !important;
  background-color: #151412 !important;
}

h1.page-title {
  font-size: 4rem;
  font-weight: 900;
}
.name {
  font-family: "satisfyregular", cursive;
  font-size: 1.9rem;
  display: none;
}
nav.headroom--not-top {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #151412 !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
  z-index: 999999;
}
.navbar-nav a.nav-link {
  font-weight: 700;
}

i.fa.fa-facebook-square:not(.footer-fa) {
  color: #4267b2;
}
i.fa.fa-instagram:not(.footer-fa) {
  color: #e95950;
}

div .text-black-50,
p .text-black-50 {
  color: rgba(0, 0, 0, 0.91) !important;
}

.edgtf-parallax-section-holder {
  position: static;
  padding: 0;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center 0;
  background-attachment: fixed;
  overflow: hidden;
}
.edgtf-btn.edgtf-btn-solid {
  background-color: #c7a17a;
  border: 2px solid #c7a17a;
  color: #fff;
}

.edgtf-btn {
  display: inline-block;
  position: relative;
  outline: 0;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 2.5px;
  padding: 14px 35px;
  font-size: 13px;
  line-height: 1.7em;
  text-transform: uppercase;
  font-family: open sans, sans-serif;
  -webkit-transition: color 0.3s cubic-bezier(0.55, 0.21, 0.51, 0.75),
    background-color 0.3s cubic-bezier(0.55, 0.21, 0.51, 0.75),
    border-color 0.3s cubic-bezier(0.55, 0.21, 0.51, 0.75);
  -moz-transition: color 0.3s cubic-bezier(0.55, 0.21, 0.51, 0.75),
    background-color 0.3s cubic-bezier(0.55, 0.21, 0.51, 0.75),
    border-color 0.3s cubic-bezier(0.55, 0.21, 0.51, 0.75);
  transition: color 0.3s cubic-bezier(0.55, 0.21, 0.51, 0.75),
    background-color 0.3s cubic-bezier(0.55, 0.21, 0.51, 0.75),
    border-color 0.3s cubic-bezier(0.55, 0.21, 0.51, 0.75);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.edgtf-lighter-effect.edgtf-lighter-effect-top {
  // top: -26px;
}

.edgtf-lighter-effect {
  position: absolute;
  z-index: 20;
  display: block;
}

a.navbar-brand img {
  height: 32px;
  width: 65px;
}

div.about-sep {
  top: 86.99rem;
}

.edgtf-lighter-effect.edgtf-lighter-effect-bottom {
  // bottom: -26px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.img-fluid {
  width: 100%;
}

.tooltip-class .tooltip-inner {
  width: 150px !important;
  // background-color: darkgreen;
  // font-size: 125%;
}

.lead {
  color: #464242 !important;
}

.logo-hero {
  width: 25rem;
  background-color: rgba(33, 33, 33, 0.6);
  border-radius: 7px;
}

@media (max-width: 991.98px) {
  .logo-hero {
    width: 15rem;
    top: -3rem;
    position: relative;
  }

  .c-cards {
    margin-top: -10rem;
    z-index: 9;
  }

  .hide-mobile {
    display: none !important;
  }
}

button.slick-next {
  right: -8px;
}

button.slick-prev {
  left: -8px;
}

.btn-teal {
  color: #fff;
  background-color: #16545f;
  // background-image: none;
  border-color: teal !important;
}
.btn-teal:hover {
  color: #16545f;
  border: #16545f solid 1px;
}

.btn-outline-teal {
  color: #16545f;
  background-color: transparent;
  background-image: none;
  border-color: #16545f !important;
}

.btn-outline-teal:hover {
  color: #fff;
  background-color: #16545f;
  border-color: #16545f !important;
}

@media (max-width: 991.98px) {
  .leftpadding {
    padding-left: 0px !important;
  }
}

section.bg-dark,
div.bg-dark {
  background-color: #151412 !important;
}
nav.navbar.navbar-transparent.headroom--pinned
  .navbar-toggler
  .navbar-toggler-icon {
  color: #151413;
  background-color: #151413;
}
nav.navbar-transparent.headroom--pinned .navbar-nav .nav-link,
nav.navbar-transparent.headroom--pinned .collapse.show .navbar-nav .nav-link,
nav.navbar-transparent.headroom--unpinned .collapse.show .navbar-nav .nav-link {
  color: #fff !important;
}

nav.navbar-transparent.headroom--unpinned .navbar-nav .nav-link,
nav.navbar-transparent.headroom--not-top.headroom--pinned
  .navbar-nav
  .nav-link {
  color: #fff !important;
}

/* 20. preloader */
/* line 585, ../../SAIFUL/Running_project/250 eCommerce_HTML/250 eCommerce_HTML/250 eCommerce_HTML/assets/scss/_common.scss */
.preloader {
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  margin: 0 auto;
}

/* line 601, ../../SAIFUL/Running_project/250 eCommerce_HTML/250 eCommerce_HTML/250 eCommerce_HTML/assets/scss/_common.scss */
.preloader .preloader-circle {
  width: 100px;
  height: 100px;
  position: relative;
  border-style: solid;
  border-width: 3px;
  border-top-color: #000;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 10;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
  box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
  background-color: #ffffff;
  -webkit-animation: zoom 2000ms infinite ease;
  animation: zoom 2000ms infinite ease;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

/* line 622, ../../SAIFUL/Running_project/250 eCommerce_HTML/250 eCommerce_HTML/250 eCommerce_HTML/assets/scss/_common.scss */
.preloader .preloader-circle2 {
  border-top-color: #0078ff;
}

/* line 625, ../../SAIFUL/Running_project/250 eCommerce_HTML/250 eCommerce_HTML/250 eCommerce_HTML/assets/scss/_common.scss */
.preloader .preloader-img {
  position: absolute;
  top: 50%;
  z-index: 200;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-top: 6px;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

/* line 643, ../../SAIFUL/Running_project/250 eCommerce_HTML/250 eCommerce_HTML/250 eCommerce_HTML/assets/scss/_common.scss */
.preloader .preloader-img img {
  //max-width: 55px;
  //animation-name: spin3D;
  //animation-duration: 1s;
  //animation-iteration-count: infinite;
  ///* linear | ease | ease-in | ease-out | ease-in-out */
  //animation-timing-function: ease-in-out;
}
@keyframes spin3D {
  from { transform: rotateY(0deg) }
  to { transform: rotateY(360deg) }
}
.preloader .preloader-img img:hover {
  animation-name: spin3D;
  animation-duration: 600s;
  animation-iteration-count: 1;
  /* linear | ease | ease-in | ease-out | ease-in-out */
  animation-timing-function: ease-in-out;
}

/* line 646, ../../SAIFUL/Running_project/250 eCommerce_HTML/250 eCommerce_HTML/250 eCommerce_HTML/assets/scss/_common.scss */
.preloader .pere-text strong {
  font-weight: 800;
  color: #dca73a;
  text-transform: uppercase;
}

@-webkit-keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
}

@keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
}
p{
  line-height: 1.7;
  margin-bottom: 1rem;
  font-weight: 300;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
     margin-bottom: 0.5rem;
     font-family: inherit;
     font-weight: 400;
     line-height: 1.5;
     color: #32325d;
   }



@media (min-width: 480px) {
  .exile-text-head-2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.exile-text-head-2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 800;
  --tw-text-opacity: 1;
  color: rgb(var(--color-text-dark)/var(--tw-text-opacity));
}
