@include media-breakpoint-up(lg) {
	.container-lg {
		max-width: 1160px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1250px;
	}
}
